<template>
    <header class="bg-white shadow-sm dark:border-gray-700 dark:bg-gray-800">
        <div>
            <nav
                class="mx-auto flex gap-x-4 max-w-7xl items-center justify-between p-6 lg:px-8"
                aria-label="Global">
                <div class="flex items-center gap-4">
                    <a
                        href="/"
                        class="-m-1.5 p-1.5">
                        <LogoImage class="h-5" />
                    </a>
                    <Icon name="heroicons:plus-16-solid" />
                    <a
                        href="https://www.jugend-zaehlt.de"
                        target="_blank">
                        <img
                            src="/jugend-zaehlt/logo.png"
                            class="max-w-16 max-h-16">
                    </a>
                </div>
                <div
                    v-if="authStore.isAuthenticated"
                    class="flex gap-x-4 lg:gap-x-6 justify-end">
                    <!-- Separator -->
                    <div
                        class="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200"
                        aria-hidden="true" />

                    <UserMenu />
                </div>
            </nav>
        </div>
    </header>
</template>

<script setup>
import {useAuthStore} from "@/store/auth";

const authStore = useAuthStore()
</script>
