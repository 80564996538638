<template>
    <div>
        <div
            id="page-container"
            class="h-screen overflow-auto bg-gray-100 dark:bg-gray-800">
            <HeaderJugendZaehlt />
            <main class="py-10 max-w-7xl mx-auto min-h-screen">
                <div class="sm:px-6 lg:px-8">
                    <!-- Your content -->
                    <slot />
                </div>
            </main>
            <StatisticsFooter />
        </div>
    </div>
</template>

<script setup>
import StatisticsFooter from '@/components/pages/jugendzaehlt/statistics/StatisticsFooter'
import HeaderJugendZaehlt from '@/components/layouts/HeaderJugendZaehlt'

useHead({
    bodyAttrs: {
        class: 'h-full bg-gray-50'
    },
    htmlAttrs: {
        class: 'h-full'
    }
})
</script>
