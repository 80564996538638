<template>
    <footer class="bg-white w-screen shadow-sm dark:border-gray-700 dark:bg-gray-800">
        <div class="py-4 sm:py-10 px-4 sm:px-0 max-w-7xl mx-auto">
            <div class="sm:px-6 lg:px-8 py-4 sm:py-0">
                <div class="mb-6">
                    <a
                        href="https://www.jugend-zaehlt.de"
                        target="_blank">
                        <img
                            src="/jugend-zaehlt/logo.png"
                            class="max-w-24 max-h-24">
                    </a>
                </div>
                <div class="flex flex-col gap-4">
                    <FootNote reference="1">
                        {{ $t('jugendzaehlt.generalInfoOne') }}
                        <a
                            href="https://www.jugend-zaehlt.de"
                            class="text-primary"
                            target="_blank">
                            www.jugend-zaehlt.de
                        </a>
                        {{ $t('jugendzaehlt.generalInfoTwo') }}
                    </FootNote>
                    <FootNote reference="2">
                        {{ $t('jugendzaehlt.demographicInfo') }}
                    </FootNote>
                    <FootNote reference="3">
                        {{ $t('jugendzaehlt.abbreviationMeaning') }}
                    </FootNote>
                    <FootNote reference="4">
                        {{ $t('jugendzaehlt.diaconicData') }}
                    </FootNote>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup>

</script>

<style scoped></style>
